* {
   box-sizing: border-box;
}

body {
   margin: 0;
   background-color: whitesmoke;
   font-family: "DM Mono", monospace;
}

.extra-space {
   margin-top: 0;
   margin-bottom: 60px;
}
