.meme {
   position: relative;
   max-width: 600px;
   margin: auto;
}

.meme > img {
   width: 100%;
}

.meme > h2 {
   position: absolute;
   width: 80%;
   text-align: center;
   left: 50%;
   transform: translateX(-50%);
   margin: 15px 0;
   padding: 0 5px;
   font-family: "DM Mono", monospace;
   font-size: 2em;
   text-transform: uppercase;
   color: white;
   letter-spacing: 1px;
   text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
      -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
      2px 2px 5px #000;
}

.meme > .bottom {
   bottom: 0;
}

.meme > .top {
   top: 0;
}

.meme-form {
   max-width: 600px;
   margin: 20px auto;
   display: flex;
   justify-content: space-between;
}

.meme-form > input {
   width: 45%;
   height: 40px;
}

.meme-form > input::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   font-family: "DM Mono", monospace;
   font-size: 25px;
   text-align: center;
}
.meme-form > input::-moz-placeholder {
   font-weight: 700;
   /* Firefox 19+ */
   font-family: "DM Mono", monospace;
   font-size: 25px;
   text-align: center;
}
.meme-form > input:-ms-input-placeholder {
   font-weight: 700;
   /* IE 10+ */
   font-family: "DM Mono", monospace;
   font-size: 25px;
   text-align: center;
}
.meme-form > input:-moz-placeholder {
   font-weight: 700;
   /* Firefox 18- */
   font-family: "DM Mono", monospace;
   font-size: 25px;
   text-align: center;
}
.meme-form > input {
   margin-right: 10px;
   font-weight: 700;
   font-family: "DM Mono", monospace;
   font-size: 25px;
   text-align: center;
}

@media only screen and (max-width: 750px) {
   .meme {
      width: 90%;
   }
   .meme-form {
      flex-direction: column;
      align-items: center;
   }
   .meme-form > input {
      margin-right: 0;
      width: 90%;
   }
   .meme-form > input:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 10px;
   }
}

/*Download button*/
.download {
   position: absolute;
   margin-top: 10px;
   left: 50%;
   transform: translateX(-50%);
}
.download,
.meme-form > button {
   cursor: pointer;
   border: none;
   font-family: "DM Mono", monospace;
   font-size: 25px;
   letter-spacing: 1.5px;
   color: white;
   background: -webkit-linear-gradient(
      to bottom,
      #6441a5,
      #2a0845
   ); /* Chrome 10-25, Safari 5.1-6 */
   background: linear-gradient(to bottom, #6441a5, #2a0845);
}
